<template>
    <div id="footer" class="narrow-content" :style="`height: ${height}`">

        <!-- Investment representation -->
        <div id="investment-representation" class="flex-center">
            <div id="investment-representation-toggle-wrap">
                <div id="investment-representation-toggle" class="btn margin-align-c" @click="ToggleInvestmentRepresentation()">
                    <img :src="investmentRepresentation.toggleSource" alt="Arrow - Up" title="Arrow - Up" width="40" height="23"/>
                </div>
            </div>
            <div id="investment-representation-content" :class="['span', investmentRepresentation.classes.contentToggleState]" :style="`height: ${investmentRepresentation.styles.contentHeight}`">
                <div class="narrow-content" v-if="!investmentRepresentation.expanded">
                    <div class="flex-center layout-50 m-rows span">
                        <div id="introduction-collapsed" class="layout single">
                            <p>All donations are invested into education</p>
                        </div>
                        <div class="layout">
                            <InvestmentChannels :investments="TotalEducationInvestments()" labelsPosition="Right"/>
                        </div>
                    </div>
                </div>
                <div id="investment-representation-content-expanded" v-show="investmentRepresentation.expanded">
                    <div class="flex-center layout-33 m-rows">
                        <div id="introduction-expanded" class="layout flex">
                            <p class="flex-right">All donations are invested into education</p>
                        </div>
                        <div class="layout">
                            <InvestmentChannels :investments="investmentRepresentation.investments.Education" labelsPosition="Top"/>
                        </div>
                        <div class="layout">
                            <InvestmentChannels :investments="investmentRepresentation.investments.Other" labelsPosition="Top"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-x-center footer-content">
            <a href="/">
                <img class="logo" src="@/assets/Images/LogoWhite.png" width="190" height="52" :alt="projectName" :title="projectName"/>
            </a>
            <div id="footer-navigation" class="p-desktop">
                <Navigation :navigation="navigation.desktop"/>
            </div>
        </div>
        <div class="footer-content p-desktop">
            <div class="flex-x-center social">
                <SocialIcon
                    type="Facebook"
                    source="IconFacebook.png"
                    width="40"
                    url="https://www.facebook.com/futureforwardeducation"
                />
                <SocialIcon
                    type="Instagram"
                    source="IconInstagram.png"
                    width="40"
                    url="https://www.instagram.com/futureforwardeducation/"
                />
                <SocialIcon
                    type="LinkedIn"
                    source="IconLinkedIn.png"
                    width="40"
                    url="https://www.linkedin.com/company/97404002"
                />
            </div>
            <p id="address" class="align-c"><i>4645 Coliseum St., Los Angeles CA, 90016</i></p>
        </div>
        <div class="footer-content p-mobile">
            <div class="flex-x-center social">
                <SocialIcon
                    type="Facebook"
                    source="IconFacebook.png"
                    width="40"
                    url="https://www.facebook.com/futureforwardeducation"
                />
                <SocialIcon
                    type="Instagram"
                    source="IconInstagram.png"
                    width="40"
                    url="https://www.instagram.com/futureforwardeducation/"
                />
                <SocialIcon
                    type="LinkedIn"
                    source="IconLinkedIn.png"
                    width="40"
                    url="https://www.linkedin.com/company/97404002"
                />
            </div>
            <p id="address" class="align-c"><i>4645 Coliseum St., Los Angeles CA, 90016</i></p>
        </div>
    </div>
</template>

<script>
import SocialIcon from "@/components/SocialIcon.vue";
import Navigation from "@/components/Template/Navigation";
import InvestmentRepresentation from "@/assets/JS/InvestmentRepresentation.json";
import InvestmentChannels from "@/components/InvestmentChannels/";
import { Animation } from "@/assets/JS/Animation.js";
import { MASTER } from "@/assets/JS/Master.js";
import $ from "jquery";
export default {
    name: "Footer",
    props: {
        projectName: String,
        navigation: Object
    },
    components: {
        SocialIcon,
        Navigation,
        InvestmentChannels
    },
    data: () => ({
        investmentRepresentation: {
            investments: InvestmentRepresentation,
            expanded: false,
            styles: { contentHeight: "40px" },
            classes: { contentToggleState: "" },
            toggleSource: "",
            height: 150
        },
        height: "0",
        mobile: false
    }),
    methods: {
        ToggleInvestmentRepresentation: function() {
            this.investmentRepresentation.expanded = !this.investmentRepresentation.expanded;
            const expanded = this.investmentRepresentation.expanded;
            const expandedContentHeight = !this.mobile ? "200px" : "400px";
            this.investmentRepresentation.styles.contentHeight = expanded ? expandedContentHeight : "40px";
            new Animation(
                $("#investment-representation-content-expanded"),
                "Fade",
                expanded ? 1 : 0,
                expanded ? 500 : 0,
                0
            );
            const toggleSource = expanded ? "ArrowDownHollow.png" : "ArrowUpHollow.png";
            this.investmentRepresentation.toggleSource = require(`@/assets/Images/${toggleSource}`);
            this.investmentRepresentation.classes.contentToggleState = expanded
                ? "content-expanded"
                : "content-collapsed";

            // Update body/footer height
            this.height = expanded
                ? Number(this.height.replace("px", "")) + this.investmentRepresentation.height + "px"
                : "380px";
            this.$emit("adjust-body-height", {
                height: this.investmentRepresentation.height,
                expanded
            });

        },
        TotalEducationInvestments: function() {
            const amounts = [];
            for (const investment of this.investmentRepresentation.investments.Education) {
                amounts.push(investment.Amount);
            }
            return [{
                Amount: Math.floor(MASTER.Numbers.Average(amounts))
            }];
        }
    },
    beforeMount: function() {
        this.investmentRepresentation.toggleSource = require("@/assets/Images/ArrowUpHollow.png");
    },
    mounted: function() {
        this.mobile = MASTER.Mobile();
        this.height = !this.mobile ? "380px" : "auto";
    },
    emits: [ "adjust-body-height" ]
};
</script>