<template>
    <div v-if="!mobile">
        <ul class="flex-x-center">
            <li :id="ID(navigationItem.Name)" v-for="(navigationItem, n) in navigation" :key="n">
                <div v-if="navigationItem.Active">
                    <router-link
                        :to="navigationItem.Route.Route"
                        :class="NAVIGATION.State($route.fullPath, navigationItem)"
                        @mouseover="OpenSubNavigation(n)"
                        @mouseout="CloseSubNavigation()"
                    >
                        {{ navigationItem.Name }}
                    </router-link>
                    <SubNavigation
                        :subNavigationItems="navigationItem.Children"
                        :show="showSubNavigation && subNavigationIndex === n"
                        @mouseover="OpenSubNavigation(n)"
                        @mouseout="CloseSubNavigation()"
                    />
                </div>
            </li>
        </ul>
    </div>
    <div v-else>
        <div class="m-pad m-primary-navigation row">
            <img class="btn" src="@/assets/Images/M/PrimaryNavigationMenu.png" alt="Icon - Primary Navigation Menu" title="Primary Navigation Menu" @click="ToggleMobileNavigation()"/>
        </div>
        <div v-for="(navigationItem, n) in navigation" :key="n">
            <div :id="ID(navigationItem.Name)" v-if="navigationItem.Active && mobileState.showNavigation">
                <div v-if="!navigationItem.Children.length">
                    <router-link
                        :to="navigationItem.Route.Route"
                        :class="NAVIGATION.State($route.fullPath, navigationItem)"
                        @click="mobileState.showNavigation = false"
                    >
                        {{ navigationItem.Name }}
                    </router-link>
                </div>
                <div v-else>
                    <a :class="NAVIGATION.State($route.fullPath, navigationItem)" @click="ToggleMobileSubNavigation(n)">
                        {{ navigationItem.Name }}
                    </a>
                </div>
                <SubNavigation
                    :subNavigationItems="navigationItem.Children"
                    :show="(mobileState.showSubNavigation && subNavigationIndex === n) || !(subNavigationIndex !== n)"
                    :mobile="mobile"
                    @mouseover="OpenSubNavigation(n)"
                    @mouseout="CloseSubNavigation()"
                    @click="mobileState.showNavigation = false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SubNavigation from "@/components/Template/Navigation/SubNavigation.vue";
import { NAVIGATION } from "@/assets/JS/Navigation.js";
export default {
    name: "Navigation",
    props: {
        navigation: Array,
        showDropdowns: Boolean,
        mobile: Boolean,
        location: String
    },
    components: { SubNavigation },
    data: () => ({
        showSubNavigation: false,
        subNavigationIndex: -1,
        mobileState: {
            showNavigation: false,
            showSubNavigation: false,
            navigation: []
        },
        NAVIGATION
    }),
    methods: {
        OpenSubNavigation: function(subNavigationIndex) {
            if (this.showDropdowns) {
                this.showSubNavigation = true;
                this.subNavigationIndex = subNavigationIndex;
            }
        },
        CloseSubNavigation: function() {
            if (this.showDropdowns) {
                this.showSubNavigation = false;
            }
        },
        ToggleMobileNavigation: function() { this.mobileState.showNavigation = !this.mobileState.showNavigation; },
        ToggleMobileSubNavigation: function(subNavigationIndex) {
            if (this.showDropdowns) {
                this.mobileState.showSubNavigation = !this.mobileState.showSubNavigation;
                this.subNavigationIndex = subNavigationIndex;
            }
        },
        ID: function(navigationItem) { return navigationItem.toLowerCase().replace(/ /gi, "-"); }
    }
};
</script>