var $;
var MASTER = {
    Construct: function(jquery) { $ = jquery; },
    Mobile: function() { return $("#platform").find(".p-mobile").css("display") === "block"; },
    Numbers: {
        Average: function(numbers) {
            let total, a;
            total = 0;
            for (a = 0; a < numbers.length; a++) {
                total += numbers[a];
            }
            return total / numbers.length;
        }
    }
};

module.exports = { MASTER };