<template>
    <div id="donation-preview-prompt" class="flex-center">
        <p class="align-c" v-html="message"></p>
    </div>
</template>

<script>
export default {
    name: "DonationPreviewPrompt",
    props: { message: String }
};
</script>