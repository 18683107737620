class Animation {
    $instance = null;
    type = "";
    value = "";
    duration = 0;
    Finish = function() {};
    constructor($instance, type, value, duration, delay, Finish) {
        this.$instance = $instance;
        this.type = type;
        this.value = value;
        this.duration = duration;
        this.Finish = Finish;
        const instance = this;
        if (duration > 0) {
            if (delay > 0) {
                setTimeout(() =>
                    instance.Animate(),
                    delay
                );
            } else {
                instance.Animate();
            }
        } else {
            $instance.css(this.SetProperties());
        }
    };
    SetProperties = function() {
        let properties = {};
        switch(this.type) {
            case "Fade":
                properties = { opacity: this.value };
                break;
            case "Left":
                properties = { left: this.value };
                break;
            case "Top":
                properties = { top: this.value };
                break;
            case "Scroll Top":
                properties = { scrollTop: this.value };
                break;
            case "Scroll Left":
                properties = { scrollLeft: this.value };
                break;
            case "Width":
                properties = { width: this.value };
                break;
            case "Height":
                properties = { height: this.value };
                break;
            case "Fade Top":
                properties = {
                    opacity: this.value,
                    top: this.value
                };
                break;
        }
        return properties;
    };
    Animate = function() {
        const instance = this;
        this.$instance.stop().animate(
            instance.SetProperties(),
            instance.duration,
            function() {
                instance.Finish !== undefined ? instance.Finish() : function() {}
            }
        );
    };
    Stop = function() { this.$instance.stop(true); };
};

module.exports = { Animation };