<template>
    <div class="investment-channels flex-wrap">
        <div :class="classes.columnize">
            <InvestmentChannel
                v-for="(investment, i) in investments"
                :key="i"
                :investment="investment"
                :labelsPosition="labelsPosition"
            />
        </div>
    </div>
</template>

<script>
import InvestmentChannel from "@/components/InvestmentChannels/InvestmentChannel.vue";
export default {
    name: "InvestmentChannels",
    props: {
        investments: Array,
        labelsPosition: String
    },
    components: { InvestmentChannel },
    data: () => ({ classes: { columnize: "" } }),
    beforeMount: function() {
        this.classes.columnize = this.investments.length > 2 ? "flex flex-wrap columned" : "";
    }
};
</script>