<template>
    <div class="investment-channel">
        <div class="labels row" v-if="labelsPosition === 'Top'">
            <span class="investment">{{ investment.Investment }}</span>
            <span class="m-right right"><b>{{ investment.Amount }}%</b></span>
        </div>
        <div class="flex-center">
            <div class="bar row">
                <div class="bar-value" :style="`width: ${investment.Amount}%; background-color: ${AmountBarColor()}`"></div>
            </div>
            <div class="labels margin-l" v-if="labelsPosition === 'Right'"><b>{{ investment.Amount }}%</b></div>
        </div>
    </div>
</template>

<script>
import { CONFIG } from "@/assets/JS/Config.js";
export default {
    name: "InvestmentChannel",
    props: {
        investment: Object,
        labelsPosition: String
    },
    methods: {
        AmountBarColor: function() {
            let color = "";
            const { Amount } = this.investment;
            if (Amount >= 75) {
                color = CONFIG.theme.orange;
            } else if (Amount >= 40 && Amount < 75) {
                color = "#bb5d39";
            } else {
                color = "#923636";
            }
            return color;
        }
    }
};
</script>