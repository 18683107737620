<template>
    <Header :projectName="CONFIG.projectName" :navigation="navigation"/>
    <div id="body" :style="`padding-bottom: ${styles.bodyPaddingBottom}px`">
        <router-view/>
    </div>
    <Footer
        :projectName="CONFIG.projectName"
        :navigation="navigation"
        @adjust-body-height="AdjustBodyHeight($event)"
    />
</template>

<script>
import Header from "@/components/Template/Header.vue";
import Footer from "@/components/Template/Footer.vue";
import { CONFIG } from "@/assets/JS/Config.js";
import Navigation from "@/assets/JS/Navigation.js";
export default {
name: "App",
    components: {
        Header,
        Footer
    },
    data: () => ({
        CONFIG,
        navigation: {
            desktop: Navigation.DESKTOP,
            mobile: Navigation.MOBILE
        },
        styles: { bodyPaddingBottom: 0 },
        bodyPaddingBottom: 600
    }),
    methods: {
        AdjustBodyHeight: function(investmentsRepresentation) {
            this.styles.bodyPaddingBottom = investmentsRepresentation.expanded
                ? this.bodyPaddingBottom + investmentsRepresentation.height
                : this.bodyPaddingBottom;
        }
    },
    beforeMount: function() { this.styles.bodyPaddingBottom = 600; }
};
</script>